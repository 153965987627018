let SheetPrinter = {

    print: async function(options) {
        
        let canSkipPdfConversion = System.Engine.Blink || System.Engine.Gecko;

        /* If we have a raw HTML content, we need to convert it to PDF link */

        if (options.item.type == 'text/html' && (!canSkipPdfConversion || options.item.content)) {
            let { convertPDF } = await requireAsync(['core/helpers/services']);
            options.item = await convertPDF(options.item);
        }

        /* If we have a link an HTML or PDF document, we can print it directly */

        let url;

        if (options.item.type == 'text/html') {
            url = options.item.url;

            if (location.host == 'localhost:8888') {
                url = url.replace('https://salonhub.dev/current/', 'http://localhost:8888/');
            }
        }

        if (options.item.type == 'application/pdf') {
            let request = await fetch(options.item.url);
            let blob = await request.blob();
            url = URL.createObjectURL(blob);
        }
    
        let frame = document.createElement('iframe');
        frame.src = url;
        frame.onload = () => {
            setTimeout(() => {
                frame.focus();
    
                setTimeout(() => {
                    frame.contentWindow.print();
    
                    document.body.addEventListener('click', () => {
                        if(frame) {
                            frame.remove();
                        }
                    }, { once: true });
    
                    setTimeout(() => {
                        if(frame) {
                            frame.remove();
                        }
                    }, 180 * 1000)
                }, 500);
            }, 500);
        }
    
        document.body.appendChild(frame);
        
        setTimeout(() => {
            if (options.callback) {
                options.callback();
            }
        }, 1500);
    },

    getPrinters: async function() {
        return [];
    },

    getCapabilities: async function() {
        let capabilities = {
            silent:     false,
            default:    false
        };

        return capabilities
    }
}

DeviceManager.Internal.SheetPrinter = SheetPrinter;