if (typeof DeviceManager.Internal.ReceiptPrinter.Renderer === 'undefined') {
    DeviceManager.Internal.ReceiptPrinter.Renderer = {};
}

DeviceManager.Internal.ReceiptPrinter.Renderer.Native = {
    
    kick: async function(configuration, drawer) {
        if (System.Runtime.Electron) {
            ReceiptPrinterEncoder = require('../../../core/lib/point-of-sale/receipt-printer-encoder.umd');
        } else {
            ReceiptPrinterEncoder = await requireAsync([ 'core/lib/point-of-sale/receipt-printer-encoder.umd' ])
        }

        let encoder = new ReceiptPrinterEncoder(configuration);

        encoder.pulse(drawer - 1);

        return encoder.encode();
    },

    render: async function(configuration, settings, receipt) {

        let ReceiptPrinterEncoder;
        
        if (System.Runtime.Electron) {
            ReceiptPrinterEncoder = require('../../../core/lib/point-of-sale/receipt-printer-encoder.umd');
        } else {
            ReceiptPrinterEncoder = await requireAsync([ 'core/lib/point-of-sale/receipt-printer-encoder.umd' ])
        }


        /* Disable logo if not set */

        if (settings.logo.enabled === -2) {
            if (!settings.logo.image || !settings.logo.image.url) {
                settings.logo.enabled = 0;
            }
        }

        let encoder = new ReceiptPrinterEncoder(Object.assign({
            feedBeforeCut:  3
        }, configuration));

        encoder
            .initialize()
            .codepage('auto');

        for (let item of receipt) {
            if (item.type === 'logo') {

                /* Determine size of image */

                let width = 480;
                let height = 240;
                
                if (encoder.columns < 40) {
                    width = 384;
                    height = 192;
                }

                /* Automatic logo */
                
                if (settings.logo.enabled === -1) {
                    /* To do, need to work out how to get the salon logo */
                }

                /* Custom logo */
                
                if (settings.logo.enabled === -2) {
                    const image = new Image();
                    image.crossOrigin = "anonymous";
                    image.src = `${settings.logo.image.url}?w=${width}&h=${height}&bg=white&fit=contain`;
                    await image.decode();

                    /* Until we can do any width and height, we should use multiples of 8 */

                    encoder.image(image, Math.round(image.width / 8) * 8, Math.round(image.height / 8) * 8, 'atkinson')
                } 

                /* Predefined logo */
                
                if (settings.logo.enabled > 0) {
                    /* To do, needs to be implemented in ReceiptPrinterEncoder */

                    encoder.raw(encoder.language == 'esc-pos' ? [ 28, 112, settings.logo.enabled, 0 ] : [ 27, 28, 112, settings.logo.enabled, 0 ])
                    encoder.newline()
                }
            }

            if (item.type === 'title') {

                if (item.hidden && settings.logo.enabled !== 0) {
                    continue;
                }

                encoder
                    .width(2)
                    .text(item.value)
                    .width(1)
                    .newline();
            }
    
            if (item.type === 'block') {
                let resized = false;

                if (item.value !== "\n") {
                    if (item.width && item.width > encoder.columns) {
                        resized = true;
                        encoder.font('B')
                    }

                    encoder.text(item.value)

                }

                encoder.newline();

                if (resized) {
                    encoder.font('A')
                }
            }
    
            if (item.type === 'mutation') {
                encoder.table(
                    [
                        { width: encoder.columns - 12, marginRight: 2, align: 'left' },
                        { width: 10, align: 'right', verticalAlign: 'bottom' }
                    ],

                    [
                        [ item.value || ' ', item.amount ]
                    ]
                )
            }
    
            if (item.type === 'total') {
                encoder.table(
                    [
                        { width: encoder.columns - 12, marginRight: 2, align: 'left' },
                        { width: 10, align: 'right', verticalAlign: 'bottom' }
                    ],

                    [
                        [ 
                            '', 
                            encoder => encoder.rule({ style: 'double' })
                        ],
                        [ 
                            encoder => item.value ? encoder.bold().text(item.value).bold() : '', 
                            encoder => encoder.bold().text(item.amount).bold(), 
                        ]
                    ]
                )
            }
    
            if (item.type === 'line') {
                encoder.rule({ style: 'single' })
            }


            if (item.type === 'test') {
                encoder
                    .line('Huidige instellingen:')
                    .rule({ style: 'single' })
                    .line('Printer: ' + settings.printer)

                if (settings.model == 'auto') {
                    encoder.line('Model: Automatisch')
                } 
                else if (settings.model == '') {
                    encoder.line('Model: Handmatig')
                    encoder.line('Type: ' + settings.language + ' compatible')
                    encoder.line('Papierbreedte: ' + settings.width)
                } 
                else {
                    encoder.line('Model: ' + settings.model)
                }

                encoder.newline();

                encoder
                    .line('Is de printer goed of fout ingesteld? ')
                    .rule({ style: 'single' })

                    .raw([ 29, 33, 16 ])
                    .text(encoder.language == "esc-pos" ? "GOED " : "FOUT ")
                    .raw([ 29, 33, 0 ])

                    .text(' / ')

                    .raw([ 27, 87, 1 ])
                    .text(encoder.language != "esc-pos" ? " GOED" : " FOUT")
                    .raw([ 27, 87, 0 ])

                    .newline()
                    .newline()
                    .newline()
                    .newline()
                    .line(`Staat de tekst 'FOUT' hierboven groot gedrukt? Kies dan een ander model, of gebruikt 'Handmatig' en pas het 'Type' aan naar '${encoder.language != "esc-pos" ? "ESC/POS" : "Star"}' compatible'`)
                    .newline()

                    .line('Ondersteuning euro teken:')
                    .rule({ style: 'single' })
                    .line('€')
                    .newline()

                    .line('Ondersteuning tekst stijlen:')
                    .rule({ style: 'single' })
                    .width(2).text('Grote tekst').width(1)
                    .newline()
                    .bold().text('Vette tekst').bold()
                    .newline()
                    .font('b').text('Kleine tekst')
                    .newline().font('a')
                    .newline()

                    .line('Ondersteuning barcodes:')
                    .rule({ style: 'single' })
                    .barcode('90311017', 'ean8')
                    .newline()
                    .barcode('6947681516939', 'ean13')
                    .newline()
                    .barcode('885909669738', 'upca')
                    .newline()
                    .barcode('SH1302020012', 'code39')
                    .newline()
                    
                    .line('Ondersteuning QR codes:')
                    .rule({ style: 'single' })
                    .qrcode('https://salonhub.nl')
                    .newline()

                    .line('Printervoorkeuze 1:')
                    .rule({ style: 'single' })
                    .raw(encoder.language == 'esc-pos' ? [ 28, 112, 1, 0 ] : [ 27, 28, 112, 1, 0 ])
                    .newline()
                    .newline()

                    .line('Printervoorkeuze 2:')
                    .rule({ style: 'single' })
                    .raw(encoder.language == 'esc-pos' ? [ 28, 112, 2, 0 ] : [ 27, 28, 112, 2, 0 ])
                    .newline()
                    .newline()

                    .line('Printervoorkeuze 3:')
                    .rule({ style: 'single' })
                    .raw(encoder.language == 'esc-pos' ? [ 28, 112, 3, 0 ] : [ 27, 28, 112, 3, 0 ])
                    .newline()
                    .newline()

                    .line('Printervoorkeuze 4:')
                    .rule({ style: 'single' })
                    .raw(encoder.language == 'esc-pos' ? [ 28, 112, 4, 0 ] : [ 27, 28, 112, 4, 0 ])
                    .newline()
                    .newline()


                if (settings.logo.image && settings.logo.image.url) {
                    /* Determine size of image */

                    let width = 480;
                    let height = 240;
                    
                    if (encoder.columns < 40) {
                        width = 384;
                        height = 192;
                    }

                    /* Load image */

                    const image = new Image();
                    image.crossOrigin = "anonymous";
                    image.src = `${settings.logo.image.url}?w=${width}&h=${height}&bg=white&fit=contain`;
                    await image.decode();
    
                    /* Until we can do any width and height, we should use multiples of 8 */
    
                    encoder
                        .line('Eigen logo:')
                        .rule({ style: 'single' })
                        .image(image, Math.round(image.width / 8) * 8, Math.round(image.height / 8) * 8, 'atkinson')
                        .newline()
                }
            }
        }
    
        encoder.cut()
    
        return encoder.encode();
    }
};