Runtime.Platform = Class.create({
	initialize: function(controller) {
		this.controller = controller;
		window.addEventListener('message', this.handleEvent.bindAsEventListener(this));
	},

	handleEvent: function(event) {
		if (event.data.response) {
			if (this._callbacks[event.data.response]) {
				this._callbacks[event.data.response].apply(this, [ event.data.result ]);
			}
		}

		if (event.data.command) {
			var command = event.data.command;
			var callback = event.data.callback;

			if (Runtime.Platform[command]) {
				var args = event.data.data;
				args.push(function() {
					if (callback) {
						if (event.source) {
							event.source.postMessage({
								response: callback,
								result:	Array.prototype.slice.call(arguments)
							}, '*');
						}
					}
				});

				(Runtime.Platform[command].bind(this.controller)).apply(this, args);
			}
		}
	},

	command: async function() {
		return new Promise(resolve => {
			if (arguments.length < 1) return;

			var command = arguments[0];
			var data = Array.prototype.slice.call(arguments).slice(1);
			var callback = this.registerCallback(resolve);

			this.controller.main.loader.browser.contentWindow.postMessage({
				command:	command,
				data:		data,
				callback:	callback
			}, '*');
		});
	},

	registerCallback: function(callback) {
		if (typeof callback == 'function') {
			id = uniqueid();
			if (typeof this._callbacks == 'undefined') this._callbacks = {};
			this._callbacks[id] = callback;
			return id;
		}

		return callback;
	}
});





Runtime.Platform.initialize = function(response) {
	var api = this.api;

	if (this.main.loader.data.stage == 'admin' && this.main.loader.data.actor == 'import') {
		api = "https://import.salonhub.nl/000/";
	}

	response({
		api:				api,
		guid:				this.settings.guid,
		account:			this.account,
		credentials:		this.main.loader.data,
		tokens:				this.tokens,
		keys:				this.keys,
		
		options:			Object.assign({}, Settings.options, this.options, {
								autoPrintReceipt:		this.settings.devices.receiptPrinter.auto,
							}),

		capabilities:		{
								paymentTransaction:		this.settings.devices.paymentTerminal.language == 'CCV 2-staps' || this.settings.devices.paymentTerminal.language == 'ccv-cloud-connect',
								kickCashDrawer:			!! this.settings.devices.cashDrawer.connection,
								displayLines:			!! this.settings.devices.customerPole.connection,
								displayDefault:			!! this.settings.devices.customerPole.connection,
								printReceipt:			! System.OS.Android,
							}
	});
};



/* View management */

Runtime.Platform.initializeWrapper = function(data, response) {
	if (data.lock) {
		this.main.locked = data.lock.locked;
	}

	this.main.disableOverlay();
	this.main.view.update(data, response);
}

Runtime.Platform.activateView = function(id, response) {
	this.main.view.select(id);
}

Runtime.Platform.clearActions = function(response) {
	this.main.view.actions = [];
	response(true);
}

Runtime.Platform.addActions = function(item, response) {
	this.main.view.actions = [ ...this.main.view.actions, item ];
	response(true);
}

Runtime.Platform.enableOverlay = function(response) {
	this.main.enableOverlay();
	response(true);
}

Runtime.Platform.disableOverlay = function(response) {
	this.main.disableOverlay();
	response(true);
}

Runtime.Platform.connectionStatusUpdate = function(status, method, response) {
	ConnectionStatus.update(status, method);
	response(true);
}

Runtime.Platform.connectionStatusShow = function(response) {
	ConnectionStatus.showWindow();
	response(true);
}

Runtime.Platform.reload = function() {
	window.location.reload();
};

Runtime.Platform.close = function() {
	window.close();
};



/* Functionality provided by the platform */

Runtime.Platform.openInBrowser = function(url) {
	window.open(url, '_blank');
};

Runtime.Platform.exportFile = async function(item, response) {
	let handle;
        
	if (window.showSaveFilePicker) {
		let type = item.type;
		
		if (type == 'text/html') {
			type = 'application/pdf';
		}

		try {
			handle = await window.showSaveFilePicker({
				types: [
					{
						description: FileType.getNameFromType(type),
						accept: {
							[ type ]: [ '.' + FileType.getExtensionFromType(type) ],
						},
					},
				],
			});	
		}
		catch(e) {
			response();
			return;
		}
	}

	if (item.type == 'text/html') {
		let { convertPDF } = await requireAsync(['core/helpers/services']);
		item = await convertPDF(item);
	}

	if (item.url) {
		if (handle) {
			const writable = await handle.createWritable();
			const request = await fetch(item.url);
			await request.body.pipeTo(writable);
		}
		else {
			window.open(item.url, '_blank');
		}
	}
	else {
		if (handle) {
			const writable = await handle.createWritable();
			await writable.write(item.content);
			await writable.close();
		}
		else {
			window.open('data:' + item.type + ';charset=utf-8,' + encodeURIComponent(item.content), '_blank');			
		}
	}

	response();
};



/* Device settings */

Runtime.Platform.getDevices = async function(forceUpdate, callback) {
	callback(await this.devices.getCapabilities(forceUpdate));
}

Runtime.Platform.getExternalDeviceCapabilities = function(data, callback) {
	let devices = structuredClone(this.settings.devices);
	devices.method = data.method;
	devices.host = data.host;
	devices.guid = data.guid;
	devices.key = data.key;
	devices.local = data.local;

	if (devices.method == 'external') {
		new DeviceManager.External({
			application:		this.settings.guid,
			settings:			devices,

			keepAlive: 			false,
			displayDefault: 	false,

			onInitialized: 		(manager) => {
									callback(manager.capabilities || {});
									window.setTimeout(() => manager.destroy(), 1000);
								},

			onFailed: 			() => {
									callback({});
								}
		});
	}
	else {
		callback({});
	}
}

Runtime.Platform.testDevice = function(type, devices) {
	DeviceManager.Test(this.settings.guid, type, devices);
}

Runtime.Platform.storeDevicesSettings = function(data) {

	/* Store devices settings */
	this.settings.devices = data;
	Runtime.Storage.write('settings', this.settings);

	/* Update device manager */
	this.devices.update(data);
	this.update();
}




/* Functionality provided by the device manager */

Runtime.Platform.supports = function(name, response) {
	response (this.devices.supports(name));
};

Runtime.Platform.paymentTransaction = function(receipt, response) {
	this.devices.paymentTransaction(receipt, response);
};

Runtime.Platform.printReceipt = function(receipt, response) {
	this.devices.printReceipt(receipt, response);
};

Runtime.Platform.kickCashDrawer = function() {
	this.devices.kickCashDrawer();
};

Runtime.Platform.displayLines = function(lineOne, lineTwo) {
	this.devices.displayLines(lineOne, lineTwo);
};

Runtime.Platform.displayDefault = function() {
	this.devices.displayDefault();
};