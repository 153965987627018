DeviceManager.Internal.ReceiptPrinter.Fallback = {

    print: async function(settings, receipt, callback) {
        let rendered = await DeviceManager.Internal.ReceiptPrinter.Renderer.Fallback.render(settings, receipt);

        /* Create iframe for printing */

        let frame = document.createElement('iframe');
        frame.src = 'about:blank';
        document.body.appendChild(frame);


        /* Write rendered receipt to iframe */

        frame.contentWindow.document.write(rendered);

        await new Promise(resolve => setTimeout(resolve, 500));


        /* Focus iframe */

        frame.focus();

        await new Promise(resolve => setTimeout(resolve, 500));


        /* Print iframe */
        
        frame.contentWindow.print();
    
        document.body.addEventListener('click', () => {
            if(frame) {
                frame.remove();
            }
        }, { once: true });

        setTimeout(() => {
            if(frame) {
                frame.remove();
            }
        }, 180 * 1000)

        if (callback) {
            callback();
        }
    }
}