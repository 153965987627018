(function() {

    let scanner = null;
    let connected = false;
    let configuration = null;

    let BarcodeScanner = {

        initialize: async function(args) {
            configuration = args;

            /* Disconnect the previous scanner */

            if (scanner && scanner.disconnect) {
                scanner.disconnect();
            }

            /* Initialize the new scanner */

            if (configuration.connection == 'hid') {
                let WebHIDBarcodeScanner = await requireAsync([ 'core/lib/point-of-sale/webhid-barcode-scanner.umd' ])

                scanner = new WebHIDBarcodeScanner();

                scanner.addEventListener('connected', e => {
                    connected = true;
                });

                scanner.addEventListener('disconnected', e => {
                    connected = false;
                });

                scanner.addEventListener('barcode', e => {
                    Runtime.Loader.Platform.command('barcode', e);
                });

                scanner.reconnect(configuration.device);
            }
        },

        start: async function () {
            if (scanner && scanner.disconnect) {
                scanner.disconnect();
            }

            if (configuration.connection == 'webcam') {
                let WebcamBarcodeScanner = await requireAsync([ 'core/lib/point-of-sale/webcam-barcode-scanner.umd' ])

                scanner = new WebcamBarcodeScanner({
                    workerPath: '../../../core/lib/point-of-sale/webcam-barcode-scanner.worker.js',
                    binaryPath: 'https://cdn.jsdelivr.net/gh/NielsLeenheer/WebcamBarcodeScanner@main/dist/webcam-barcode-scanner.wasm',

                    allowedSymbologies: [ 'ean13', 'ean8', 'upca', 'upce', 'qr-code' ],
                    beepOnScan: true,
                    preview: {
                        size: 160,
                        draggable: true,
                        position: 'bottom-right',
                        padding: {
                            top: 60, 
                            left: 20,
                            right: 30,
                            bottom: 20
                        }
                    }
                });

                scanner.addEventListener('connected', e => {
                    connected = true;
                });

                scanner.addEventListener('disconnected', e => {
                    connected = false;
                });

                scanner.addEventListener('barcode', e => {
                    Runtime.Loader.Platform.command('barcode', e);
                });

                scanner.connect();
            }
        },

        stop: function () {
            if (scanner && scanner.disconnect) {
                scanner.disconnect();
            }
        },

        getCapabilities: async function() {
            let capabilities = {
                keyboard:   true,
                webcam:     true,
            };
    

            if (navigator.hid) {
                capabilities.hid = {
                    manual: true,
                    devices: []
                };
            }
    
            return capabilities;
        },

        get connected() {
            return connected;
        }
    };

    DeviceManager.Internal.BarcodeScanner = BarcodeScanner;
})();

