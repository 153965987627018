CSS.insert(`
	ui-window.waitForTerminal .status { position: relative; text-align: center; line-height: 40px; color: #666; padding-top: 24px; text-transform: var(--font-transform); margin-top: 30px; }
	ui-window.waitForTerminal .status { background: url(${_ROOT}images/loader-regular.gif) top center no-repeat; background-size: 24px 24px; }
	@media (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) { ui-window.waitForTerminal .status { background-image: url(${_ROOT}images/loader-regular@2x.gif); } }
	ui-window.waitForTerminal .explaination { text-align: center; padding: 0 20px; font-size: 0.8em; }
`);

DeviceManager.Internal.PaymentTerminal.Wait = Class.create();
DeviceManager.Internal.PaymentTerminal.Wait.prototype = {
	initialize: function(options) {
        this.options = Object.assign({
            onResult:   () => {}
        }, options || {});

        this.count = 0;
        this.timer = setInterval(() => this.check(), 10 * 1000);
        this.check();
	},

    check: async function() {
        this.count++;

        let status = await this.fetch();

        if (status == 'ready' || status == 'manualintervention' || status == 'error') {
            this.close(status);
            return;
        }

        if (this.count > 6) {
            this.close('timeout');
            return;
        }

        this.create();
    },

    fetch: async function() {
        let statusResponse = await fetch(`${this.options.endpoint}api/Payments.Terminals/status/${this.options.client}:${this.options.salon}?provider=ccv-cloud-connect`, {
            method:     'POST',
            body:       JSON.stringify({ device: this.options.device }),
            headers:    {
                'Content-Type': 'application/json'
            }
        });

        if (statusResponse.ok) {
            let statusResult = await statusResponse.json();
            return statusResult.status;
        }

        return 'error';
    },

    create: function() {
        if (!this.window) {
            this.window = new Window({
                container: 	document.body,
                className:  'waitForTerminal',
                width: 		260,
                height:		240
            });

            this.title = new Element('div', { 'className': 'status' }).update('Wachten op betaalterminal...');
            this.window.container.appendChild(this.title);
    
            this.explaination = new Element('div', { 'className': 'explaination' }).update('De betaalterminal is nog bezig met een transactie. Wacht tot de transactie is afgerond.');
            this.window.container.appendChild(this.explaination);

            new Widgets.Button(this.window.navigation, {
                title:		'Stop',
                color:		'red',
                onClick:	() => {
                    this.close('stopped');
                }
            });

            this.window.show();
        }
    },

    close: function(status) {
        if (this.timer) {
            clearInterval(this.timer);
        }

        if (this.window) {
            this.window.close();
        }

        this.options.onResult(status);
    },
};
