Export = {
    initialize: function(parent, controller, actions) {
        Export._controller = controller;
        Export._actions = actions.filter(i => i.category == 'export');

        Export._button = document.createElement('button');
        Export._button.id = 'exports';
        Export._button.innerHTML = 'e';
        Export._button.disabled = !Export._actions.length;
        Export._button.addEventListener('click', () => { Export.show() });
        parent.appendChild(Export._button);
    },

    show: function() {
		if (Export._actions.length == 0) {
			return;
		}

		let items = [];

		Export._actions.forEach(action => {
			let icon = 'file-empty';
			let extension = FileType.getExtensionFromType(action.type);

			if (action.type == 'text/html' || extension == 'pdf') {
				icon = 'file-pdf';
			}

			if (extension == 'xls' || extension == 'xlsx') {
				icon = 'file-excel';
			}

			if (extension == 'csv') {
				icon = 'file-csv';
			}

			if (extension == 'xml') {
				icon = 'file-xml';
			}

			items.push({
				icon:		action.icon ? action.icon : icon,
				title:		action.title,
				enabled:	typeof action.enabled === 'undefined' || action.enabled,
				onSelect:	function(item) { Export.handle(item) }.bind(this, action)
			});
		});

        let offset = Export._button.viewportOffset();

		setTimeout(() => {
			Export._button.dataset.state = 'active';

			new Widgets.ContextMenu({
				x:						offset.left + 15,
				y:						offset.top + 30,
				align:					'right',
				verticalAlign:			'top',
				overlay:				! document.body.classList.contains('overlay'),
				items:					items,
				onClose:				() => delete Export._button.dataset.state
			});
		}, 0);
    },

    handle: async function(item) {
		item = Object.assign({
			name:		'rapportage.html',
		}, item);

		if (item.type == 'action') {
			let result = await Runtime.Loader.Platform.command('callback', {
				callback:	item.action
			});

			if (!result) {
				return;
			}

			item = result;
		}

		let status = new Window.Status('Exporteren...');

		Runtime.Platform.exportFile.apply(Export._controller, [ item, () => {
			setTimeout(() => {
				status.close();
			}, 1000);
		}]);
    },

    update: function(actions) {
        Export._actions = actions.filter(i => i.category == 'export');

        if (Export._button) {
            Export._button.disabled = !Export._actions.length;
        }
    }
}