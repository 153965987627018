Runtime.Controller = Class.create({
	initialize: async function(parameters) {
		Runtime.Controller.current = this;
		
        this.api = Settings.api;
        this.parameters = parameters;
        this.session = {};
        
		this.previous = null;

		try {
			let previous = window.localStorage.getItem('account');
			if (previous) {
				this.previous = previous;
			}
		} catch(e) {
		}

        this.account = {
            client: null,
            login: {},
            permissions: {}
        };

		this.settings = await this.retrieveSettings();

		this.devices = new DeviceManager({
			application:		this.settings.guid,
			settings:			this.settings.devices,

			onUpgradeSettings:	(settings) => {
				this.settings.devices = settings;
				Runtime.Storage.write('settings', this.settings);
			}
		});

		this.main = new Runtime.Main(this);

		if (!this.account.login) {
			this.login();
		}
	},

	getClient: function() {
		if (this.account.client) {
			return this.account.client;
		}

		if (this.parameters.client) {
			return this.parameters.client;
		}

		if (System.Features.Standalone && this.previous) {
			return this.previous;
		}

		return '';
	},

    prefix: function() {
		let account = this.getClient();

		if (account) {
			return account + '-';
		}

		return '';
    },

	login: function() {
		new Runtime.Login(this);
	},

	update: function() {
		this.main.connect();
	},

	scrollToTop: function() {
		window.scrollTo(0,0);
	},

	retrieveSettings: async function() {
		let settings = await Runtime.Storage.read({
			name:		'settings',
			defaults:	{
				guid:				MD5(String(Math.random())),
				version:			null,
				account:			null,
				salon:				null,
				salons:				[],
				devices:			{}
			}
		});

		if (typeof settings.version == 'undefined') {
			settings.version = null;
		}

		if (Settings.version != settings.version) {
			Settings.firstrun = true;
			settings.version = Settings.version;
		}

		if (typeof settings.guid == 'undefined') {
			settings.guid = MD5(String(Math.random()));
		}

		settings = Object.assign({
			'devices':		{}
		}, settings || {})

		settings.devices = Object.assign({
			'method':		'internal',
			'host':			'',
			'guid':			'',
			'key':			'',
			'name':			'',
			'local':		false,
		}, settings.devices || {})

		settings.devices.receiptPrinter = Object.assign({
			'location':		'external',
			'method':		'',
			'printer':		'',
			'model':		'',
			'language':		'',
			'width':		42,
			'size':			'',
			'source':		'',
			'font':			'',
			'logo':			{
				enabled:		0,
				image:	        {
					id:				null,
					url:			null
				}
			},
		}, settings.devices.receiptPrinter || {});

		settings.devices.sheetPrinter = Object.assign({
			'location':		'internal',
			'printer':		'*'
		}, settings.devices.sheetPrinter || {});

		settings.devices.cashDrawer = Object.assign({
			'location':		'external',
			'connection':	'',
			'port':			'',
			'language':		'',
			'drawer':		1
		}, settings.devices.cashDrawer || {});

		settings.devices.customerPole = Object.assign({
			'location':		'external',
			'connection':	'',
			'port':			'',
			'language':		'',
			'defaultOne':	'',
			'defaultTwo':	''
		}, settings.devices.customerPole || {});

		settings.devices.externalDisplay = Object.assign({
			'location':		'internal',
			'method':		'',
			'display':		null,
			'font':			'',
			'size':			'normal',	
			'photos':		[],
			'background':	{
				id:				null,
				url:			null
			}
		}, settings.devices.externalDisplay || {});

		settings.devices.paymentTerminal = Object.assign({
			'location':		'',
			'connection':	'',
			'port':			'',
			'language':		'',
			'register':		1
		}, settings.devices.paymentTerminal || {});

		settings.devices.barcodeScanner = Object.assign({
			'location':		'internal',
			'connection':	'keyboard',
			'id':			'',
			'device':		{},
		}, settings.devices.barcodeScanner || {});


		/* Upgrade settings */

		if (typeof settings.devices.sheetPrinter.method != 'undefined') {
			settings.devices.sheetPrinter.location = settings.devices.sheetPrinter.method;
			delete settings.devices.sheetPrinter.method;
		}

		Runtime.Storage.write('settings', settings);

		return settings;
	},

	getActors: function() {
		var actors = [];

		var permissions = this.account.permissions;
		
        if (this.account.login) {
            if (this.account.client == 'admin' && permissions.rights & _USER_ADMIN_) {
				actors.push({
					name:		'Administratie',
					icon:		'\uE611',
					stage:		'admin',
					actor:		'main',
                    rights:     permissions.rights
				})

				if (permissions.rights & _USER_IMPORT_) {
					actors.push({
						name:		'Importeren',
						icon:		'',
						stage:		'admin',
						actor:		'import',
                        rights:     permissions.rights
					})
				}
            }
            else {
				if (permissions.salons) {
					var ids = Object.keys(permissions.salons);
					ids.forEach(function(id) {
						actors.push(Object.assign({
							stage: 'salon',
							actor: id
						}, permissions.salons[id]));
					});

					actors.sort(function(a, b) {
						return a.fullname.localeCompare(b.fullname);
					})
				}

				if (actors.length) {
					actors.unshift({});
				}
				
				if (permissions.capabilities && permissions.capabilities.globalMonitor && permissions.rights & _USER_VIEW_DASHBOARD_) {
					actors.unshift({
						'name': 'Filiaal monitor',
						'stage': 'admin',
						'actor': 'dashboard',
						'rights': permissions.rights
					});
				}

				actors.unshift({
					'name': permissions.rights & _USER_VIEW_ADMIN_ ? 'Beheer' : 'Instellingen',
					'stage': 'admin',
					'actor': 'settings',
					'rights': permissions.rights
				});
            }
		}

		return actors;
	}
});
