Sidebar = {

    attach: function(element) {
        element.addEventListener('click', () => Sidebar.show());
    },

    initialize: function(element, controller) {
        Sidebar._element = element;
        Sidebar._element.innerHTML = '';
        Sidebar._element.addEventListener('click', Sidebar.close);


        /* Retreive data */

        var account = controller.account ? controller.account : controller.settings.account;
        var login = account.login ? account.login : account;


        /* Create containers */

        var wrapper = new Element('div', { className: 'wrapper' });
		Sidebar._element.appendChild(wrapper);

		this.trapFocus(wrapper);

        let view;


        /* Header */

        var logo = new Element('div', { className: 'logo' });
		wrapper.appendChild(logo);

        var search = new Element('div', { className: 'search' });
		wrapper.appendChild(search);

        new Widgets.Search(search, {
			minChars: 	    3,
            clearButton:    true,
			onQuery:	    (query) => view.query(query)
		});


        /* View */
        
        view = new SidebarView({
            element:        wrapper,

            actors:         controller.getActors(),
            current:        controller.main.loader.data,
            isAdmin:        (account.permissions ? account.permissions : account).rights & 64,

            onSelect:       (actor) => {
                controller.main.loader.change(actor);
                Sidebar.hide();
            }
        })


        /* Log out */

        var buttons = new Element('div', { className: 'buttons' });
		wrapper.appendChild(buttons);

        var loggedin = new Element('div', { className: 'loggedin' });
        loggedin.innerHTML = `<span>Ingelogd bij <strong>${account.client}</strong> met gebruiker <strong>${login.username}</strong><span>`;
        buttons.appendChild(loggedin);

		var button = new Element('button', { className: 'button logout' }).update('<span class="icon"></span><span class="label" title="' + login.username + '">Uitloggen</span>');
		button.observe('click', () => { Sidebar.hide(); controller.main.logout(); });
        buttons.appendChild(button);
       
        document.body.dataset.sidebar = 'hidden';
    },

    render: function(query) {

    },

    destroy: function() {
        delete document.body.dataset.sidebar;

        setTimeout(() => {
            Sidebar._element.innerHTML = '';
        }, 200);
    },

    trapFocus: function(element) {
        Sidebar._internal = {};
		Sidebar._internal.focusTrap = {};

		Sidebar._internal.focusTrap.frontCatch = new Element('div');
		Sidebar._internal.focusTrap.frontCatch.tabIndex = 0;
		Sidebar._internal.focusTrap.frontCatch.onfocus = () => Sidebar._internal.focusTrap.endTarget.focus();
		element.insertAdjacentElement('beforebegin', Sidebar._internal.focusTrap.frontCatch)

		Sidebar._internal.focusTrap.frontTarget = new Element('div');
		Sidebar._internal.focusTrap.frontTarget.tabIndex = -1;
		element.insertAdjacentElement('beforebegin', Sidebar._internal.focusTrap.frontTarget)

		Sidebar._internal.focusTrap.endCatch = new Element('div');
		Sidebar._internal.focusTrap.endCatch.tabIndex = 0;
		Sidebar._internal.focusTrap.endCatch.onfocus = () => Sidebar._internal.focusTrap.frontTarget.focus();
		element.insertAdjacentElement('afterend', Sidebar._internal.focusTrap.endCatch)

		Sidebar._internal.focusTrap.endTarget = new Element('div');
		Sidebar._internal.focusTrap.endTarget.tabIndex = -1;
		element.insertAdjacentElement('afterend', Sidebar._internal.focusTrap.endTarget)
    },

    show: function() {
        if (!Sidebar._element) {
            return;
        }

        if (document.body.classList.contains('overlay')) {
            return;
        }

        Sidebar._overlay = new Overlay({
            owner: Sidebar._element,
			onHide: Sidebar.hide,
			zIndex: 9999,
			animate: true
		});

        Sidebar._element.classList.add('visible');

        setTimeout(() => {
            if (Sidebar._internal.focusTrap.frontTarget) {
                Sidebar._internal.focusTrap.frontTarget.focus();
            }
        }, 100);

        document.body.dataset.sidebar = 'visible';
    },

    hide: function() {
        if (!Sidebar._element) {
            return;
        }

        Sidebar._overlay.destroy();
        Sidebar._element.classList.remove('visible');
        Sidebar._element.blur();

        document.body.dataset.sidebar = 'hidden';
    }
};





class SidebarView {

    #options;
    #current;
    #rendered = false;

    #header;
    #list;

    constructor(options) {
        this.#options = Object.assign({
            element:        null,
            actors:         null,
            current:        null,
            isAdmin:        false,
            onSelect:       () => {}
        }, options || {});


        this.#current = structuredClone(this.#options.current);

        this.#header = document.createElement('div');
        this.#header.classList.add('views');
		this.#options.element.appendChild(this.#header);

        this.#list = document.createElement('ul');
		this.#options.element.appendChild(this.#list);

        this.#render();

        this.#rendered = true;
    }

    query(query) {
        this.#render(query);
    }

    #select(actor) {
        this.#list.querySelectorAll('.selected').forEach(i => i.classList.remove('selected'));

        this.#current = structuredClone(actor);
        this.#options.onSelect(actor);
    }

    #render(query) {

        if (this.#rendered) {
            this.#header.innerHTML = '';
            this.#list.innerHTML = '';
        }

        if (!query) {

            /* Dashboard */

            let dashboard = this.#options.actors.filter(i => i.stage == 'admin' && i.actor == 'dashboard').pop();

            if (dashboard) {
                let button = document.createElement('button');
                button.classList.add('button', 'dashboard');
                button.innerHTML = `<span class="icon"></span><span class="label">Filiaal monitor</span>`;
                button.addEventListener('click', () => {
                    this.#select(dashboard);
                });

                this.#header.appendChild(button);
            }

            /* Settings */

            let settings = this.#options.actors.filter(i => i.stage == 'admin' && i.actor == 'settings').pop();

            if (settings) {
                let button = document.createElement('button');
                button.classList.add('button', 'settings');
                button.innerHTML = `<span class="icon"></span><span class="label">${settings.name}</span>`;
                button.addEventListener('click', () => {
                    this.#select(settings);
                });

                this.#header.appendChild(button);
            }

            /* Items */

            var items = this.#options.actors.filter(i => i.stage == 'admin' && (i.actor != 'dashboard' && i.actor != 'settings'));

            items.forEach(i => {
                let item = document.createElement('li');
                item.classList.add('item');
                item.tabIndex = 0;
                item.innerHTML = `<div class='name'><span class='icon'>${i.icon}</span><em>${i.name}</em></div>`;
                
                item.addEventListener('click', () => {
                    this.#select(i);
                    item.classList.add('selected');
                });

                item.addEventListener('keypress', e => {
                    if (e.key != 'Enter') return;

                    this.#select(i);
                    item.classList.add('selected');
                });
                                    
                if (this.#current.stage == i.stage && this.#current.actor == i.actor) {
                    item.classList.add('selected');
                }

                this.#list.insertBefore(item, this.#list.lastChild);
            });
        }


        /* Active salons */

        var items = this.#options.actors.filter(i => i.stage == 'salon' && i.id && i.status == 'active');

        if (query) {
            items = items.filter(i => i.fullname.toLowerCase().includes(query.toLowerCase()) || i.formula.toLowerCase().includes(query.toLowerCase()));
        }

        items.forEach(i => {
            let url;
            let size = System.Features.Retina ? 80 : 40;
            
            if (i.photo) {
                url = `${i.photo}?w=${size}&h=${size}&fit=crop`;
            }

            let item = document.createElement('li');
            item.classList.add('salon');
            item.tabIndex = 0;
            item.innerHTML = `<div class='photo'></div><div class='name'><em>${escapeHTML(Formatter.salon(i))}</em><br>${escapeHTML(Formatter.formula(i))}</div>`;

            item.addEventListener('click', () => {
                this.#select(i);
                item.classList.add('selected');
            });

            item.addEventListener('keypress', e => {
                if (e.key != 'Enter') return;

                this.#select(i);
                item.classList.add('selected');
            });

            if (this.#current.stage == i.stage && this.#current.actor == i.actor) {
                item.classList.add('selected');
            }

            if (url) {
                if (this.#rendered) {
                    item.querySelector('.photo').style.backgroundImage = `url(${url})`;
                }
                else {
                    setTimeout(() => {
                        item.querySelector('.photo').style.backgroundImage = `url(${url})`;
                    }, 1000)
                }
            }

            this.#list.appendChild(item);
        });



        if (!query) {

            /* Archived salons */

            if (this.#options.isAdmin) {
                var archivedWrapper = new Element('li', { className: 'archived hidden' });
                archivedWrapper.hide();
                this.#list.appendChild(archivedWrapper);

                var archivedTitle = new Element('h3');
                archivedWrapper.appendChild(archivedTitle);
                archivedTitle.addEventListener('click', e => {
                    archivedWrapper.classList.toggle('hidden');
                })

                var archivedList = new Element('ul');
                archivedWrapper.appendChild(archivedList);

                var items = this.#options.actors.filter(i => i.stage == 'salon' && i.id && i.status == 'archived');

                if (items.length) {
                    items.forEach(i => {
                        let item = document.createElement('li');
                        item.classList.add('salon');
                        item.tabIndex = 0;
                        item.innerHTML = escapeHTML(i.fullname);

                        item.addEventListener('click', () => {
                            this.#select(i);
                            item.classList.add('selected');
                        });
                        
                        if (this.#current.stage == i.stage && this.#current.actor == i.actor) {
                            this.#list.classList.add('selected');
                            archivedWrapper.classList.remove('hidden');
                        }

                        archivedList.appendChild(item);
                    });

                    archivedTitle.innerHTML = 'Gearchiveerd (' + items.length + ')';
                    archivedWrapper.show();
                }
            }
        }
    }
}
