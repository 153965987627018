if (typeof DeviceManager.Internal.ReceiptPrinter.Renderer === 'undefined') {
    DeviceManager.Internal.ReceiptPrinter.Renderer = {};
}

DeviceManager.Internal.ReceiptPrinter.Renderer.Fallback = {
    
    render: async function(settings, receipt) {
        let result = `
        
            <style>
    
                @page {
                    margin: 0;
                }
    
                @media print {
                    body {
                        padding: 12px 
                    }
                }
                
                * {
                    font-family: monospace;
                    font-size: 12px;
                }
    
                table {
                    min-width: 42ch;
                    cell-spacing: 0;
                }
    
                td:first-child {
                    min-width: 32ch;
                }
                td:last-child {
                    min-width: 10ch;
                }
                td:only-child {
                    min-width: 42ch;
                }
    
                h3 {
                    margin-bottom: 0;
                    font-size: 16px;
                }
            </style>
        `;
        
        result += `<table>`
    
        receipt.forEach(item => {
            if (item.type === 'title') {
                result += `<tr><td colspan=2><h3>${item.value}</h3></td></tr>`;
            }
    
            if (item.type === 'block') {
                if (item.value == "\n") {
                    item.value = "<br>";
                }
    
                if ('monospace' in item && item.monospace) {
                    result += `<tr><td colspan=2><pre>${item.value}</pre></td></tr>`;
                }
                else {
                    result += `<tr><td colspan=2>${item.value || '&nbsp;'}</td></tr>`;
                }
            }
    
            if (item.type === 'mutation') {
                result += `
                    <tr>
                        <td>${item.value || '&nbsp;'}</td>
                        <td>${item.amount}</td>
                    </tr>
                `;
            }
    
            if (item.type === 'total') {
                result += `
                    <tr>
                        <td>&nbsp;</td>
                        <td>==========</td>
                    </tr>
                    <tr>
                        <td><b>${item.value || 'Totaal'}</b></td>
                        <td><b>${item.amount}</b></td>
                    </tr>
                `;
            }
    
            if (item.type === 'line') {
                result += `
                    <tr>
                        <td colspan=2>------------------------------------------</td>
                    </tr>
                `;
            }
        });
    
        result += `</table>`;
    
        return result;
    }
};