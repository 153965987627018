DeviceManager.External = Class.create({
	initialize: async function(options) {
		this.options = Object.assign({
			application:				null,
			settings:					{},	

			keepAlive:					true,
			displayDefault:				true,

			onInitialized:				function() {},
			onConnected:				function() {},
			onFailed:					function() {},
		}, options || {});

		this.settings = this.options.settings;
		this.commands = [];

		this.capabilities = {};
		this.initialized = false;
		this.working = false;
		this.logger = null;

		if (this.settings.guid) {
			let configuration;

			try {
				let response = await fetch('https://master.salonhub.nl/latest/api/PointOfSale.Client/get?guid=' + this.settings.guid + (this.settings.key ? '&key=' + this.settings.key : '') + '&application=' + this.options.application);
				configuration = await response.json();
			} catch(e) {
			}
	
			if (configuration) {				
				if (configuration.log) {
					this.logger = new DeviceLogger({ 
						application: this.options.application 
					});
				}

				if (this.logger) {
					this.logger.log('device', { event: 'configuration', data: configuration });
				}

				let parameters = {
					guid:						this.settings.guid,
					key:						this.settings.key,
					application:				this.options.application,
					configuration:				configuration,
					logger:						this.logger,

					keepAlive:					this.options.keepAlive,

					onInitialized:				this.onInitializedConnection.bind(this),
					onFailed:					this.onFailedConnection.bind(this),
					onEnabled: 					this.onEnableConnection.bind(this),
					onDisabled: 				this.onDisableConnection.bind(this),
					onUpdateCapabilities: 		this.onUpdateCapabilities.bind(this)
				}

				if (this.settings.local) {
					this.opening = new DeviceManager.External.Http(parameters);
				} else {
					this.opening = new DeviceManager.External.Pusher(parameters);
				}
			}
			else {
				this.onFailedConnection();
			}

			return;
		} 

		if (!System.Runtime.Browser) {
			this.opening = new DeviceManager.External.Http({
				host:					    this.settings.host || '127.0.0.1', 
				application:				this.options.application,
				configuration:				null,
				logger:						null,				

				keepAlive:					this.options.keepAlive,

				onInitialized:				this.onInitializedConnection.bind(this),
				onFailed:					this.onFailedConnection.bind(this),
				onEnabled: 					this.onEnableConnection.bind(this),
				onDisabled: 				this.onDisableConnection.bind(this),
				onUpdateCapabilities: 		this.onUpdateCapabilities.bind(this)
			});

			return;
		}

		this.options.onFailed();
	},

	destroy: function() {
		if (this.opening) {
			this.opening.stop();
		}

		if (this.connection) {
			this.connection.stop();
		}
	},

	queue: function(command, options) {
		this.commands.push([command, options]);
		this.run();
	},

	run: function() {
		if (this.connection) {
			var command;

			while (command = this.commands.shift()) {
				this.connection.command(command[0], command[1]);
			}
		}
	},

	onInitializedConnection: function(connection) {
		if (this.logger) {
			this.logger.log('device', { event: 'initializedConnection' });
		}

		this.opening = null;
		this.connection = connection;

		if (!this.initialized) {
			this.initialized = true;
			this.options.onInitialized(this);
		}
	},

	onFailedConnection: function() {
		if (this.logger) {
			this.logger.log('device', { event: 'failedConnection' });
		}

		if (!this.initialized) {
			this.options.onFailed();
		}
	},

	onEnableConnection: function() {
		if (this.logger) {
			this.logger.log('device', { event: 'enableConnection' });
		}

		if (!this.enabled) {
			this.enabled = true;
			this.onConnect();

			if (this.options.onConnected) {
				this.options.onConnected(this);
			}
		}
	},

	onDisableConnection: function() {
		if (this.logger) {
			this.logger.log('device', { event: 'disableConnection' });
		}

		if (this.enabled) {
			this.enabled = false;
		}
	},

	onUpdateCapabilities: function(capabilities) {

		/* Convert paymentTerminal information for backwards compatibility with POS 1.x */

		if (typeof capabilities.devices.paymentTerminal != 'undefined') {
			if (typeof capabilities.devices.paymentTerminal.languages != 'undefined' &&
				typeof capabilities.devices.paymentTerminal.items == 'undefined')
			{
				capabilities.devices.paymentTerminal.items = [];

				for (var i = 0; i < capabilities.devices.paymentTerminal.languages.length; i++) {
					capabilities.devices.paymentTerminal.items.push({
						name:		capabilities.devices.paymentTerminal.languages[i],
						features: 	[ 'transaction', 'detectMethod' ]
					});
				}
			}
		}

		this.capabilities = capabilities;
	},

	onConnect: function() {
		if (this.options.displayDefault) {
			if (this.settings.customerPole && this.settings.customerPole.connection) {
				this.displayDefault();
			}
		}

		this.run();
	},

	getVersion: function() {
		if (typeof this.capabilities.version != 'undefined') {
			return `${this.capabilities.version.Major}.${this.capabilities.version.Minor}.${this.capabilities.version.Build}`;
		}
	},

	supports: function(feature) {

        /* Check for payment terminal features */

	    if (feature == 'detectMethod') {
            if (this.settings.paymentTerminal.connection && this.capabilities.devices) {
                for (var i = 0; i < this.capabilities.devices.paymentTerminal.items.length; i++) {
                    if (this.capabilities.devices.paymentTerminal.items[i].name == this.settings.paymentTerminal.language) {
                        for (var f = 0; f < this.capabilities.devices.paymentTerminal.items[i].features.length; f++) {
                            if (this.capabilities.devices.paymentTerminal.items[i].features[f] == feature) {
                                return true;
                            }
                        }
                    }
                }
            }
        }

		/* Check for printer features */

		if (feature == 'canAutoPrint') {
			if (this.settings.receiptPrinter.printer) {
				return true;
			}
		}
		
        /* Check for global features */

		if (typeof this.capabilities.features != 'undefined') {
			for (var i = 0; i < this.capabilities.features.length; i++) {
				if (this.capabilities.features[i] == feature) {
					return true;
				}
            }
		}
	},



	/* Public API */

	retrieveCapabilities: function(callback) {
		this.queue({ command: 'requestInfo' }, {
			onSuccess: function(response) {
				if (callback) {
					callback(JSON.parse(response));
				}
			}.bind(this)
		});
	},

	requestLogs: function(callback) {
		this.queue({ command: 'requestLogs' }, {
			onSuccess: function(response) {
				if (callback) {
					callback(response);
				}
			}.bind(this)
		});
	},

	ping: function(callback) {
		this.queue({ command: 'ping' }, {
			timeout: 4,

			onSuccess: response => {
				if (callback) {
					callback(response.trim() == 'true');
				}
			},

			onFailure: () => {
				if (callback) {
					callback(false);
				}
			}
		});
	},

	/* ********************** */

	uploadLogo: function(type, logo, callback) {
		if (this.logger) {
			this.logger.log('device', { command: 'uploadLogo', enabled: this.enabled, data: { type, logo } });
		}

		if (!this.enabled) {
			return false;
		}

		let options = { type: type, url: logo };

		this.queue({ command: 'receiptPrinter/setLogo', options: options }, {
			onSuccess: response => {
				if (callback) {
					callback(response);
				}
			}
		});

		return true;
	},

	clearLogo: function(type, callback) {
		if (this.logger) {
			this.logger.log('device', { command: 'clearLogo', enabled: this.enabled, data: { type } });
		}

		if (!this.enabled) {
			return false;
		}

		var options = { type: type };

		this.queue({ command: 'receiptPrinter/clearLogo', options: options }, {
			onSuccess: response => {
				if (callback) {
					callback(response);
				}
			}
		});

		return true;
	},

	printTest: function() {
		if (this.logger) {
			this.logger.log('device', { command: 'printTest', enabled: this.enabled });
		}

		if (!this.enabled) {
			return false;
		}

		if (this.settings.receiptPrinter && this.settings.receiptPrinter.method) {
			var options = {
				language: 	this.settings.receiptPrinter.language,
				width: 		this.settings.receiptPrinter.width || '',
				source:		this.settings.receiptPrinter.source || '',
				size:		this.settings.receiptPrinter.size || ''
			};

			if (this.settings.receiptPrinter.method == 'direct') {
				options.method = 'direct';
				options.port = this.settings.receiptPrinter.printer;
			}

			if (this.settings.receiptPrinter.method == 'driver') {
				options.method = 'driver';
				options.name = this.settings.receiptPrinter.printer;

				if (options.name.match(/SRP[-_\s]?350/) && !options.name.match(/SRP[-_\s]?350II/)) {
					options.compatibility = 'DoubleMotionUnits';
				}
			}

			if (this.settings.receiptPrinter.method == 'network') {
				options.method = 'network';
				options.name = this.settings.receiptPrinter.printer;
			}

			this.queue({ command: 'receiptPrinter/test', options: options }, {
				onSuccess: response => {
					let message = '';

					switch(response) {
						case 'error':	message = 'Er is een onbekende fout opgetreden'; break;
						case 'false': 	message = 'Er is een onbekende fout opgetreden'; break;
						case 'busy': 	message = 'De geselecteerde ' + (this.settings.receiptPrinter.method == 'direct' ? 'seriële poort' : 'printer') + ' is in gebruik door een andere applicatie'; break;
						case 'failed': 	message = 'Kon geen gegevens sturen naar de geselecteerde ' + (this.settings.receiptPrinter.method == 'direct' ? 'seriële poort' : 'printer'); break;
						case 'invalid': message = 'De geselecteerde ' + (this.settings.receiptPrinter.method == 'direct' ? 'seriële poort' : 'printer') + ' is niet geldig'; break;
					}

					if (message != '') {
						new Window.Alert({
							message:		'Communicatiefout',
							explaination:	message
						});
					}
				}
			});

			return true;
		}

		if (callback) {
			callback(true);
		}

		return true;
	},

	paymentTransaction: function(receipt, callback) {
		if (this.logger) {
			this.logger.log('device', { command: 'paymentTransaction', enabled: this.enabled, data: receipt });
		}

		if (!this.enabled) {
			return false;
		}

		if (this.settings.paymentTerminal && this.settings.paymentTerminal.connection) {

			document.fire('shell:showPaymentStatus');
			this.working = true;

			let options = {
				register:	this.settings.paymentTerminal.register,
				language:	this.settings.paymentTerminal.language
			};

			if (this.settings.paymentTerminal.connection == 'direct') {
				options.connection = 'direct';
				options.port = this.settings.paymentTerminal.port;
			}

			this.queue({ command: 'paymentTerminal/transaction', options: options, data: JSON.stringify(receipt) }, {
				timeout: 200,

				onSuccess: response => {
					document.fire('shell:hidePaymentStatus');
					this.working = false;

					if (callback) {
						var status;
						var data = null;

						/* If it is not a number it is a JSON string, parse it */
						
						if (isNaN(response)) {
							try {
								status = _PAYMENT_SUCCESS;
								data = JSON.parse(response);
							} catch(e) {
							}
						}

						/* If it is a number it is a status code */

						if (!status) {
							status = parseInt(response, 10);
						}

						/* Upgrade payment method for new issuers */

						if (data?.issuer == 'AM') {						/* American Express */
							data.method = _PAYMENT_CREDITCARD_;
						}

						if (data?.issuer == 'DM') {						/* Debit Mastercard */
							data.method = _PAYMENT_DEBITCARD_;
						}

						if (data?.issuer == 'VD') {						/* VISA Debit */
							data.method = _PAYMENT_DEBITCARD_;
						}

						callback(status, data);
					}
				},

				onFailure: () => {
					document.fire('shell:hidePaymentStatus');
					this.working = false;

					if (callback) {
						callback(_PAYMENT_POS_TIMEOUT);
					}
				}
			});

			return true;
		}

		if (callback) {
			callback(false);
		}

		return true;
	},

	printSheet: function(file, callback) {
		if (this.logger) {
			this.logger.log('device', { command: 'printSheet', enabled: this.enabled, data: file });
		}

		if (!this.enabled) {
			return false;
		}

		let options = {
			name: this.settings.sheetPrinter.printer,
			url: file
		};

		this.queue({ command: 'sheetPrinter/print', options: options }, {
			timeout:	30,
			
			onSuccess: response => {
				if (callback) {
					callback(JSON.parse(response));
				}
			},

			onFailure: () => {
				if (callback) {
					callback();
				}
			}
		});

		return true;
	},

	printReceipt: function(receipt, callback) {
		if (this.logger) {
			this.logger.log('device', { command: 'printReceipt', enabled: this.enabled, data: receipt });
		}

		if (!this.enabled) {
			return false;
		}

		if (this.settings.receiptPrinter && this.settings.receiptPrinter.method) {
			let options = {
				language: this.settings.receiptPrinter.language,
				source: this.settings.receiptPrinter.source || '',
				width: this.settings.receiptPrinter.width || '',
				size: this.settings.receiptPrinter.size || '',
				logo: this.settings.receiptPrinter.logo.enabled
			};

			if (options.logo == -2) {
				options.logo = -1;
			}

			if (this.settings.receiptPrinter.method == 'direct') {
				options.method = 'direct';
				options.port = this.settings.receiptPrinter.printer;
			}

			if (this.settings.receiptPrinter.method == 'driver') {
				options.method = 'driver';
				options.name = this.settings.receiptPrinter.printer;

				if (options.name.match(/SRP[-_\s]?350/) && !options.name.match(/SRP[-_\s]?350II/)) {
					options.compatibility = 'DoubleMotionUnits';
				}
			}

			if (this.settings.receiptPrinter.method == 'network') {
				options.method = 'network';
				options.name = this.settings.receiptPrinter.printer;
			}

			this.queue({ command: 'receiptPrinter/print', options: options, data: JSON.stringify(receipt) }, {
				onSuccess: response => {
					if (callback) {
						callback(JSON.parse(response));
					}
				}
			});

			return true;
		}

		if (callback) {
			callback(true);
		}

		return true;
	},

	kickCashDrawer: function(callback) {
		if (this.logger) {
			this.logger.log('device', { command: 'kickDrawer', enabled: this.enabled });
		}

		if (!this.enabled) {
			return false;
		}

		if (this.settings.cashDrawer && this.settings.cashDrawer.connection) {
			let options = {};

			if (this.settings.cashDrawer.connection == 'receiptPrinter' && this.settings.receiptPrinter.method) {
				options.connection = 'receiptPrinter';

				if (this.settings.receiptPrinter.method == 'direct') {
					options.method = 'direct';
					options.port = this.settings.receiptPrinter.printer;
				}

				if (this.settings.receiptPrinter.method == 'driver') {
					options.method = 'driver';
					options.name = this.settings.receiptPrinter.printer;
				}

				if (this.settings.receiptPrinter.method == 'network') {
					options.method = 'network';
					options.name = this.settings.receiptPrinter.printer;
				}
				
				options.drawer = this.settings.cashDrawer.drawer;
				options.language = this.settings.receiptPrinter.language;
			}

			if (this.settings.cashDrawer.connection == 'direct') {
				options.connection = 'direct';
				options.port = this.settings.cashDrawer.port;
				options.drawer = this.settings.cashDrawer.drawer;
				options.language = this.settings.cashDrawer.language;
			}

			this.queue({ command: 'cashDrawer/kick', options: options }, {
				onSuccess: response => {
					if (callback) {
						callback(JSON.parse(response));
					}
				}
			});

			return true;
		}

		if (callback) {
			callback(true);
		}

		return true;
	},

	displayLines: function(lineOne, lineTwo, callback) {
		if (this.logger) {
			this.logger.log('device', { command: 'displayLines', enabled: this.enabled, data: [ lineOne, lineTwo ] });
		}

		if (!this.enabled) {
			return false;
		}

		if (this.settings.customerPole && this.settings.customerPole.connection) {
			let options = {
				language: this.settings.customerPole.language,
				lineOne: lineOne,
				lineTwo: lineTwo
			};

			if (this.settings.customerPole.connection == 'direct') {
				options.connection = 'direct';
				options.port = this.settings.customerPole.port;
			}

			this.queue({ command: 'customerPole/display', options: options }, {
				onSuccess: response => {
					if (callback) {
						callback(JSON.parse(response));
					}
				}
			});

			return true;
		}

		if (callback) {
			callback(true);
		}

		return true;
	},

	displayClear: function(callback) {
		if (this.logger) {
			this.logger.log('device', { command: 'displayClear', enabled: this.enabled });
		}

		if (!this.enabled) {
			return false;
		}

		if (this.settings.customerPole && this.settings.customerPole.connection) {
			let options = {
				language: this.settings.customerPole.language
			};

			if (this.settings.customerPole.connection == 'direct') {
				options.connection = 'direct';
				options.port = this.settings.customerPole.port;
			}

			this.queue({ command: 'customerPole/clear', options: options }, {
				onSuccess: response => {
					if (callback) {
						callback(JSON.parse(response));
					}
				}
			});

			return true;
		}

		if (callback) {
			callback(true);
		}

		return true;
	},

	displayDefault: function(callback) {
		if (this.logger) {
			this.logger.log('device', { command: 'displayDefault', enabled: this.enabled });
		}

		if (!this.enabled) {
			return false;
		}

		if (this.settings.customerPole && this.settings.customerPole.connection) {
			if (this.settings.customerPole.defaultOne != '' || this.settings.customerPole.defaultTwo != '') {
				return this.displayLines(this.settings.customerPole.defaultOne, this.settings.customerPole.defaultTwo, callback);
			}

			return this.displayClear(callback);
		}

		return true;
	}
});