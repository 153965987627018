DeviceManager.Internal.ReceiptPrinter = {

    enabled: function(options) {
        return false;
    },

    supports: function(feature) {
        if (feature == 'canAutoPrint') {
            return false;
        }
    },

    initialize: async function() {
    },

    kick: async function() {
    },
    
    print: async function() {
    },
 
    test: async function(settings, callback) {
        await DeviceManager.Internal.ReceiptPrinter.print(settings, [ { type: 'test' }], callback);
    }
}